<template>
    <div :class="{ 'shrinkreport-content': drawer && !isMobile }" class="mt-16">

    <v-container fluid>
        <v-row>
            <v-col v-if="dealers.length > 1" xl="2" lg="3" md="4" xs="12" sm="6">
                <label for="dealers" style="display: block">{{ $t("dealers.title") }}</label>
                <v-autocomplete
                    id="dealers"
                    dense
                    :items="dealers"
                    item-text="name"
                    item-value="id"
                    return-object
                    v-model="filterEditDealers"
                    multiple
                    chips
                    @change="dealerChanged()"
                >
                <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 1">
                        <span>{{ item.name.replace(/techno metal post/i, 'TMP').replace(/techno pieux/i, 'TP') }}</span>
                    </v-chip>
                    <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                        >
                        +{{ filterEditDealers.length - 1 }}
                    </span>
                </template>
                </v-autocomplete>
            </v-col>
            <v-col xl="2" lg="3" md="4" xs="12" sm="6">
              <label for="projecttype" style="display: block">{{ $t("reportInfo.projecttype") }}</label>
              <v-autocomplete
                id="projecttype"
                dense
                :items="projectTypes"
                item-text="name"
                item-value="id"
                return-object
                v-model="filterProjectType"
                multiple
                chips
                @change="updateMarkerCluster()"
              >
              <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 2" class="mx-1">
                    <span>{{ projectTypeText(item.name) }}</span>
                  </v-chip>
                  <span
                      v-if="index === 2"
                      class="grey--text text-caption"
                      >
                      +{{ filterProjectType.length - 2 }}
                  </span>
              </template>
              </v-autocomplete>
            </v-col>
            <v-col xl="2" lg="3" md="4" xs="12" sm="6">
              <label for="status" style="display: block">{{ $t("projects.project_status") }}</label>
              <v-autocomplete
                id="status"
                dense
                :items="status"
                item-text="name"
                item-value="id"
                return-object
                v-model="filterEditStatus"
                multiple
                chips
                @change="updateMarkerCluster()"
              >
              <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 2">
                    <span :style="{'color': item.color.text}">{{ item.name }}</span>
                  </v-chip>
                  <span
                      v-if="index === 2"
                      class="grey--text text-caption"
                      >
                      +{{ filterEditStatus.length - 2 }}
                  </span>
              </template>
              <template v-slot:item="{ item }">
                <div class="d-flex" style="margin:10px 0">
                  <v-checkbox :color=item.color.text :input-value="isSelected(item)" :hide-details="true" class="mt-0"></v-checkbox>
                  <span :style="{'color': item.color.text}" class="mt-1">{{item.name}}</span>
                </div>
              </template>
              </v-autocomplete>
          </v-col>
          <v-col xl="2" lg="3" md="4" xs="12" sm="6">
            <label for="projectDate" style="display: block" :class="datesOk ? '' : 'errored'">{{ $t("filters.date") }}</label>
            <div style="display:flex; justify-content: space-evenly;" class="mt-4">
              <v-dialog
                ref="startDateModal"
                v-model="startDateModal"
                :return-value.sync="filterDateStart"
                persistent
                width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    v-model="filterDateStart"
                    :label="$t('filters.date_start')"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    class="mr-1"
                    @click:clear="updateMarkerCluster"
                    >
                  </v-text-field>
                </template>
                <v-date-picker :first-day-of-week="getFirstDayOfWeek()" v-model="filterDateStart" scrollable :min="getMinSelectableDate()" :max="getMaxSelectableDate()">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="startDateModal = false">{{ $t('confirm.cancel') }}</v-btn>
                  <v-btn text color="primary" @click="$refs.startDateModal.save(filterDateStart); startDateModal = false; updateMarkerCluster();">OK</v-btn>
                </v-date-picker>
              </v-dialog>
              
              <v-dialog
                ref="endDateModal"
                v-model="endDateModal"
                :return-value.sync="filterDateEnd"
                persistent
                width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    v-model="filterDateEnd"
                    :label="$t('filters.date_end')"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    class="ml-1"
                    @click:clear="updateMarkerCluster"
                  >
                  </v-text-field>
                </template>
                <v-date-picker :first-day-of-week="getFirstDayOfWeek()" v-model="filterDateEnd" scrollable :min="getMinSelectableDate()" :max="getMaxSelectableDate()">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="endDateModal = false">{{ $t('confirm.cancel') }}</v-btn>
                  <v-btn text color="primary" @click="$refs.endDateModal.save(filterDateEnd); endDateModal = false; updateMarkerCluster();">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </div>
            
          </v-col>
          <v-col xl="2" lg="3" md="4" xs="12" sm="6">
            <label for="soilType" style="display: block">{{ $t("filters.soiltype") }}</label>
            <v-autocomplete
              id="soilType"
              dense
              :items="soilTypes"
              :item-text='item => $t(item.text)'
              item-value='soilTypeEnum'
              return-object
              v-model="filterEditSoilType"
              multiple
              chips
              @change="updateMarkerCluster()"
            >
            <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 2">
                  <span>{{ $t(item.text) }}</span>
                </v-chip>
                <span
                    v-if="index === 2"
                    class="grey--text text-caption"
                    >
                    +{{ filterEditSoilType.length - 2 }}
                </span>
            </template>
            <!-- <template v-slot:item="{ item }">
              <v-checkbox :color=item.color.text :input-value="isSelected(item)"></v-checkbox>
              <span :style="{'color': item.color.text}">{{item.name}}</span>
            </template> -->
            </v-autocomplete>
          </v-col>
          <v-col xl="2" lg="3" md="4" xs="12" sm="6">
              <v-text-field
                  style="margin-top: 42px;"
                  v-model="search"
                  id="autocomplete"
                  :label="$t('reportInfo.address')"
                  dense
                  clearable
                  @click:clear="removeSearchMarker(true)"
                  @keyup="removeSearchMarker(false)"
              />
          </v-col>
        </v-row>

        <div id="map" style="height:75vh;width:100%;"></div>
      
    </v-container>
  </div>
</template>

<script>

import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
import DealerService from "@/services/dealer.service";
import { getLanguage, setLocalStorageWithExpiry, getLocalStorageWithExpiry, getFirstDayOfWeek } from "@/utils/utils";
import { PROJECT_STATUS, PROJECT_STATUS_TEXT, PROJECT_STATUS_COLOR, SOIL_TYPES_INCL_NOTSET } from "@/enums.js";
import moment from 'moment';
const path = require('path');

/*global google*/

export default {
    components: {
    },
    computed: {
      ...mapState({
        isMobile: (state) => state.isMobile,
        drawer: (state) => state.drawer,
        currentUser: state => state.currentUser,
      }),
      ...mapGetters({
        hasAccessToMap: 'hasAccessToMap'
      })
    },
    data() {
      let status = [];
      for (let i in PROJECT_STATUS) {
        if (i != 'DELETED') {
          status.push({ 'color':PROJECT_STATUS_COLOR[PROJECT_STATUS[i]],  'id': PROJECT_STATUS[i], 'name': this.$t(PROJECT_STATUS_TEXT[PROJECT_STATUS[i]])});
        }
      }
      return {
        map: null,
        dealers: [],
        projectTypes: [],
        filterDealers: [],
        filterEditDealers: [],
        filterProjectType: [],
        dealerMaps: [],
        kmlColors: [ '#009688', '#FFEB3B', '#E91E63', '#2196F3', '#4CAF50', '#FFC107', '#9C27B0', '#03A9F4', '#8BC34A', '#FF9800', '#673AB7', '#00BCD4', '#CDDC39', '#FF5722', '#F44336', '#3F51B5' ],
        infoWindow: null,
        openedMarker: null,
        search: '',
        status:status,
        filterEditStatus: status.filter((e) => e.id != PROJECT_STATUS.CANCELED && e.id != PROJECT_STATUS.DELETED),
        filterEditSoilType: [],
        filterDateStart: null,
        filterDateEnd: null,
        datesOk: true,
        startDateModal: false,
        endDateModal: false,
        autocomplete: null,
        searchMarker: null,
        searchMarkerPoint: null,
        markers: [],
        updateMarkerReady: false,
        updateMarkersTimeout: null,
        soilTypes: SOIL_TYPES_INCL_NOTSET,
        getFirstDayOfWeek: getFirstDayOfWeek,
        updateMarkerTimer: null,
      };
    },
    methods: {
        log: console.warn,
        ...mapMutations(['showErrorMessage', 'openToast']),
        ...mapActions({
        }),
        projectTypeText(name) {
          return name.length < 10 ? name : name.substring(0, 7) + '...';
        },
        // Initialize and add the map
        initMap() {
            var state = this;
            this.infoWindow = new google.maps.InfoWindow({
                content: "",
                disableAutoPan: true
            });

            this.infoWindow.addListener('domready',function(){
                document.getElementById("infoWindowCloseBtn").onclick = function() {
                    if (state.infoWindow) {
                        state.infoWindow.close();
                        state.openedMarker = null;
                    }
                };

                for (let infoWindowUrl of document.getElementsByClassName("infoWindowUrl")) {
                    infoWindowUrl.onclick = function() {
                        state.$router.push(this.getAttribute('href'));
                    };
                }
            });

            navigator.geolocation.getCurrentPosition(function(pos) {
                state.initMapWithPoint({ lat: pos.coords.latitude, lng: pos.coords.longitude });
            },
            function(error) {
                console.log('error', error)
                state.openToast({ message: state.$t('map.geolocationPositionError'), duration: 15000, color: 'red'});
                 // Si nous n'avons pas la position de l'usager, on affiche la maison mère de TP
                 // Il n'y a pas de position "par défaut" de google maps, on doit donner un point central
                state.initMapWithPoint({ lat: 46.11118448049068, lng: -71.2913423841532 });
            });
        },
        initMapWithPoint(point) {
            // On filtre selon le dernier affichage
            let zoom = 10;
            let mapParams = getLocalStorageWithExpiry("mapParams");
            if (mapParams) {
              if (mapParams.projectType) this.filterProjectType = JSON.parse(JSON.stringify(mapParams.projectType));
              if (mapParams.projectStatus) this.filterEditStatus = this.status.filter(s => mapParams.projectStatus.includes(s.id));
              if (mapParams.soilTypes) this.filterEditSoilType = JSON.parse(JSON.stringify(SOIL_TYPES_INCL_NOTSET)).filter(s => mapParams.soilTypes.includes(s.soilTypeEnum));
              if (mapParams.zoom) zoom = mapParams.zoom;
              if (mapParams.center) point = mapParams.center;
              if (mapParams.marker) this.setSearchMarker(new google.maps.LatLng(mapParams.marker.lat, mapParams.marker.lng));
              if (mapParams.address) this.search = mapParams.address;
              if (mapParams.startDate) this.filterDateStart = mapParams.startDate;
              if (mapParams.endDate) this.filterDateEnd = mapParams.endDate;
            }

            let mapElement = document.getElementById("map");
            this.map = new google.maps.Map(mapElement, {
                zoom: zoom,
                center: point,
                gestureHandling: 'greedy'
            });
            this.map.setOptions({ draggableCursor:'grab' }); // Override le cursor par défaut puisqu'il diffère légèrement du curseur grab

            var state = this;
            this.map.addListener("click", () => {
                if (state.infoWindow) {
                    state.infoWindow.close();
                    state.openedMarker = null;
                }
            });

            this.map.addListener('bounds_changed', function () {

                // Refraîchir les markers après du mouvement mais seulement après 500ms de stabilité
                if (state.updateMarkersTimeout) {
                    clearTimeout(state.updateMarkersTimeout);
                }

                state.updateMarkersTimeout = setTimeout(() => {
                    state.updateMarkerCluster();
                }, 500);
            });

            this.getDealers();
        },
        async getDealers() {
            this.filterEditDealers = [];
            let response = await DealerService.getDealerMaps(getLanguage());
            if (response) {
                this.dealers = response.data.dealers;
                this.projectTypes = response.data.projectTypes;

                // On sélectionne tous les dealers disponibles par défaut
                this.filterEditDealers = this.dealers;

                // On filtre selon le dernier affichage
                let mapParams = getLocalStorageWithExpiry("mapParams");
                if (mapParams){
                    if (mapParams.dealers) this.filterEditDealers = this.filterEditDealers.filter(d => mapParams.dealers.includes(d.id));
                }
                this.filterDealers = JSON.parse(JSON.stringify(this.filterEditDealers, null, 2));

                // On centre le territoire que s'il y a un seul concessionnaire
                let fitBounds = this.filterEditDealers.length == 1;
                for (let dealer of this.dealers) {
                    fitBounds = this.filterEditDealers.length == 1 && dealer.id == this.filterEditDealers[0].id
                    await this.drawKmlCoordinates(dealer.id, dealer.coordinates, fitBounds);
                }

                this.updateMarkerReady = true;
            }
            console.log('getDealers', this.dealers)
        },
        async dealerChanged() {
            let anyAddedDealer = false;

            // Déterminer les added dealers pour aller chercher ses infos
            for (let ed of this.filterEditDealers) {
                let isPresent = false;
                for (let i = 0; i < this.filterDealers.length; i++) {
                    if (this.filterDealers[i].id == ed.id) {
                        isPresent = true;
                        break;
                    }
                }
                if (!isPresent) {
                    anyAddedDealer = true;
                    await this.drawKmlCoordinates(ed.id, ed.coordinates, true);
                    this.filterDealers.push(ed);
                }
            }

            // Déterminer les deleted dealers pour enlever ses infos
            let anyRemovedDealer = false;
            for (let i = 0; i < this.filterDealers.length; i++) {
                let filterDealer = this.filterDealers[i];
                let isPresent = false;
                for (let j = 0; j < this.filterEditDealers.length; j++) {
                    if (this.filterEditDealers[j].id == filterDealer.id) {
                        isPresent = true;
                        break;
                    }
                }
                if (!isPresent) {
                    // On efface les markers et le territoire du concessionnaire
                    let dealerMapIndex = this.dealerMaps.findIndex(m => m.id == filterDealer.id);
                    if (dealerMapIndex >= 0) {
                        anyRemovedDealer = true;

                        let dealerMap = this.dealerMaps[dealerMapIndex];

                        if (dealerMap.kml) {
                            dealerMap.kml.setMap(null);
                        }

                        this.dealerMaps.splice(dealerMapIndex, 1);
                    }

                    this.filterDealers.splice(i, 1);
                    i--;
                }
            }
            if (anyAddedDealer || anyRemovedDealer) {
                this.updateMarkerCluster();
            }
        },
        async drawKmlCoordinates(dealerId, coordinates, fitBounds) {

            let kml = null;
            let color = null;

            // On affiche le territoire s'il est configuré
            if (coordinates && coordinates.length > 0) {

                // On cycle parmis les couleurs disponibles, on sélectionne la couleur la moins affichée
                let minCount = null;
                for (let kmlColor of this.kmlColors) {
                    let count = this.dealerMaps.filter(m => m.color == kmlColor).length;
                    if (minCount == null || count < minCount) {
                        color = kmlColor;
                        minCount = count;
                    }
                }

                // Construct the polygon.
                kml = new google.maps.Polygon({
                    paths: coordinates,
                    strokeColor: color,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: color,
                    fillOpacity: 0.25
                });

                let mapElement = document.getElementById("map");

                kml.addListener('mousemove', function(e) {
                    if (!isNaN(e.edge) || !isNaN(e.vertex)) {
                        mapElement.className = '';
                    }
                    else {
                        mapElement.className = 'grab';        
                    }
                });

                kml.addListener('mouseout', function() {
                    mapElement.className = '';
                });

                kml.setMap(this.map);
                if (fitBounds) {
                    this.map.fitBounds(kml.getBounds());
                }
            }

            this.dealerMaps.push({
                id: dealerId,
                color: color,
                kml: kml
            })
        },
        isSelected(item) {
          return this.filterEditStatus.filter(e => e.id == item.id).length > 0;
        },
        setMapParams(mapParams) {
          let mapParamsOld = getLocalStorageWithExpiry("mapParams");
          if (mapParamsOld){
            mapParams = {...mapParamsOld, ...mapParams}
          }
          setLocalStorageWithExpiry("mapParams", mapParams, 1000 * 60 * 5);
        },
        removeSearchMarker(setToEmpty){
            if (setToEmpty) this.search = '';
            if (this.search.length == 0){
                this.searchMarker.setMap(null);
                this.setMapParams({marker:null, address: null});
            }
        },
        updateMarkerCluster() {
          clearTimeout(this.updateMarkerTimer);
          this.updateMarkerTimer = setTimeout(this._updateMarkerCluster, 500);
        },
        async _updateMarkerCluster() {
            if (!this.updateMarkerReady) {
              return;
            }

            this.openedMarker = null;
            
            if (this.markerClusterer) {
                this.markerClusterer.clearMarkers();
            }

            for (let marker of this.markers) {
                marker.setMap(null);
            }
            this.markers = [];

            const bounds = this.map.getBounds();
            if (!bounds) {
              return;
            }
            const northEast = bounds.getNorthEast();
            const southWest = bounds.getSouthWest();
            const zoom = this.map.zoom;

            this.datesOk = true;
            if (this.filterDateStart != null && this.filterDateEnd != null) {
              if (this.filterDateStart > this.filterDateEnd) this.datesOk = false;
              if (this.filterDateEnd < this.filterDateStart) this.datesOk = false;
            }
            
            let mapParams = {
              dealerIds: this.filterEditDealers.map(d => d.id),
              northEastLng: northEast.lng(),
              northEastLat: northEast.lat(),
              southWestLng: southWest.lng(),
              southWestLat: southWest.lat(),
              projectStatus: this.filterEditStatus.map(d => d.id),
              projectType: JSON.parse(JSON.stringify(this.filterProjectType.map(e => e.id))),
              soilTypes: this.filterEditSoilType.map(d => d.soilTypeEnum),
              startDate: this.datesOk ? this.filterDateStart : null,
              endDate: this.datesOk ? this.filterDateEnd : null,
              zoom: zoom,
            }

            let response = await DealerService.getMarkers(mapParams);

            mapParams.center = this.map.getCenter();

            mapParams.projectType = this.filterProjectType; //we send a list of ids to the backend, but we keep the list of objects on this side

            this.setMapParams(mapParams);

            if (response && response.data) {
                for (let geoData of response.data) {
                    if (geoData.properties.cluster) {
                        const color = "#0000ff";
                        const svg = window.btoa(`
                            <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                                <circle cx="120" cy="120" opacity=".6" r="70" />
                                <circle cx="120" cy="120" opacity=".3" r="90" />
                                <circle cx="120" cy="120" opacity=".2" r="110" />
                            </svg>`);

                        let marker = new google.maps.Marker({
                                position: { lat: geoData.geometry.coordinates[1], lng: geoData.geometry.coordinates[0] },
                                label: {
                                    text: String(geoData.properties.point_count_abbreviated),
                                    color: "rgba(255,255,255,0.9)",
                                    fontSize: "12px",
                                },
                                icon: {
                                    url: `data:image/svg+xml;base64,${svg}`,
                                    scaledSize: new google.maps.Size(45, 45),
                                },
                                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + geoData.properties.point_count,
                                map: this.map
                            });

                            marker.addListener('click', async function() {
                                this.map.setCenter({ lat: geoData.geometry.coordinates[1], lng: geoData.geometry.coordinates[0] });
                                this.map.setZoom(this.map.zoom+2);
                            });
                        this.markers.push(marker);
                    } 
                    else {
                        let marker = new google.maps.Marker({
                            position: { lat: geoData.geometry.coordinates[1], lng: geoData.geometry.coordinates[0] },
                            map: this.map
                        });
                        marker.projects = geoData.properties.projects;

                        var scope = this;
                        marker.addListener('mouseover', async function() {
                            scope.openMarkerDetails(marker);
                        });
                        marker.addListener('click', async function() {
                            scope.openMarkerDetails(marker);
                        });

                        this.markers.push(marker);
                    }
                }
                this.updateSearchMarker();
            }
        },
        bindMap() {
            let inputAutocomplete = document.getElementById('autocomplete');
            
            this.autocomplete = new google.maps.places.Autocomplete(inputAutocomplete);
            this.autocomplete.addListener('place_changed', () => this.onPlaceChanged());
            
            setTimeout(() => { inputAutocomplete.placeholder = "" }, 500);
            
            if (this.autocomplete) {
                this.geolocate()
            }
        },
        geolocate() {
            const scope = this
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    var geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    var circle = new google.maps.Circle({
                        center: geolocation,
                        radius: position.coords.accuracy
                    });

                    scope.autocomplete.setBounds(circle.getBounds());
                });
            }
        },
        onPlaceChanged() {
            let place = this.autocomplete.getPlace();
            if (place && place.geometry && place.geometry.location) {
                let point = place.geometry.location;
                this.map.setCenter(point);
                this.map.setZoom(11);
                this.setMapParams({marker:point, address: place.formatted_address});
                this.setSearchMarker(point);
            }
        },
        updateSearchMarker() {
            if (this.searchMarker) {
                this.searchMarker.setMap(null);
            }
            if (!this.searchMarkerPoint) {
                return;
            }

            // Arrondir à 3 décimales pour comparer
            let latitude = Math.round(this.searchMarkerPoint.lat() * 1000) / 1000;
            let longitude = Math.round(this.searchMarkerPoint.lng() * 1000) / 1000;

            let markerOnSamePoint = this.markers.find(m => (Math.round(m.position.lat() * 1000) / 1000) == latitude && (Math.round(m.position.lng() * 1000) / 1000) == longitude);
            let markerImagePath = path.join(__dirname, '../assets/img/' + (markerOnSamePoint ? 'reg-green-marker' : 'green-marker') + '.png');

            this.searchMarker = new google.maps.Marker({
                position: this.searchMarkerPoint,
                map: this.map,
                icon: markerImagePath,
                zIndex: 2000000,
            });
            if (markerOnSamePoint) {
                var scope = this;
                this.searchMarker.addListener('mouseover', async function() {
                    scope.openMarkerDetails(markerOnSamePoint);
                });
                this.searchMarker.addListener('click', async function() {
                    scope.openMarkerDetails(markerOnSamePoint);
                });
            }
        },
        setSearchMarker(point) {
            this.searchMarkerPoint = point;
            this.updateSearchMarker();
        },
        async openMarkerDetails(marker) {

            if (this.openedMarker == marker) {
                //console.log('Marker already opened...', marker)
                return;
            }

            //console.log('Retrieve marker details.', marker)

            this.openedMarker = marker;
            try {
                if (this.infoWindow) {
                    this.infoWindow.close();
                }

                let response = await DealerService.getMapProjectsDetails(marker.projects, getLanguage(), this.currentUser.id);
                if (response && response.status == 200 && response.data) {
                    
                    let content = 
                        "<div id='infoWindow'><span style='font-weight:bold;'>" + response.data.formattedAddress + "</span><br/>" +
                        response.data.formattedProjectType + "<br/>" +
                        response.data.formattedSoilType + "<br/>" +
                        response.data.formattedAverageDepth + "<br/>";

                    for (let reportLink of response.data.reportLinks) {
                        if (reportLink.url) {
                            content += "<div><span href='" + reportLink.url + "' class='infoWindowUrl'>" + reportLink.name + "</span>" + reportLink.date + "</div>";
                        }
                        else {
                            content += "<span>" + reportLink.name + "</span> " + reportLink.date + "<br/>";
                        }
                    }
                    content += `<button id="infoWindowCloseBtn" title="` + this.$t('general.close') + `"><img src="/assets/icon/close.svg" height="10" /></button></div>`;

                    this.infoWindow.setContent(content);
                    this.infoWindow.open(this.map, marker);
                }
            }
            catch {
                this.openedMarker = null;
            }
        },
        getMinSelectableDate() {
          return "1993-01-01";
        },
        getMaxSelectableDate() {
          return moment().endOf('year').add(2, 'years').format('YYYY-MM-DD');
        },
    },

    async mounted() {
        if (this.hasAccessToMap) {
            this.initMap();
            this.bindMap();
        }
        else {
            this.showErrorMessage(this.$t('general.access_denied'));
            this.$router.push('/');
        }
    }
  }
</script>

<style>
.grab,
.grab * {cursor: grab !important;}

button.gm-ui-hover-effect {
    visibility: hidden;
}
.gm-style-iw,
.gm-style-iw * {
    cursor: default!important;
}
#infoWindowCloseBtn {
    position: absolute;
    top: 2px; 
    right: 5px;
    cursor: pointer!important;
}
#infoWindowCloseBtn * {
    cursor: pointer!important;
}
.infoWindowUrl {
    margin-right: 10px;
    text-decoration: underline;
    color: green;
    cursor: pointer!important;
}
</style>
